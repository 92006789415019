

export function Spinner(props) {
    return (
        <div>
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <br/>
            <h5 className='text-center text-warning'>... Loading ...</h5>
        </div>
    )
}

export function DotSpinner(props) {
    if (props.active === true) {
        return (
            <div className="d-inline">
                <div className="spinner-grow spinner-grow-sm text-warning" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        )
    } else {
        return null;
    }
}
