import React from 'react';
import { Spinner, DotSpinner } from './utils.js';
import { Flashcard } from './flashcard.js';
import * as Icon from 'react-bootstrap-icons';


function Progress(props) {
    return (
        <div className='row text-light mb-2'>
            <div className='col-2 text-end px-1'> <Icon.Box/> {props.level}: </div>
            <div className='col-6'>
                <div className="progress" style={{height: "22px"}}>
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-secondary" style={{width: props.prc + '%'}}>
                        {props.prc}%
                    </div>
                </div>
            </div>
            <div className='col-4 text-end pe-3'> {props.value} / {props.max} </div>
        </div>
    )
}


export class Lerning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            limit: 50,
            level: 1,
            current: 0,
            flashcard: null,
            flashcards: null,
            loading: false
        }
    }

    componentDidMount() {
        this.api = this.props.api;
    }

    handleChangeVisible = () => {
        this.setState({visible: !this.state.visible});
    }

    handleChangeLimit = (event) => {
        this.setState({
            limit: event.target.value,
            flashcard: null,
            flashcards: null,
            current:0,
        });
    }

    handleChangeLevel = (event) => {
        this.setState({
            level: event.target.value,
            flashcard: null,
            flashcards: null,
            current:0,
        });
    }

    handleStart = () => {
        this.setState(
            {
                flashcard: null,
                flashcards: null,
                current:0
            },
            this.getFlashcards
        )
    }

    getFlashcards = () => {
        this.setState({loading: true});

        let reverse = false;
        let shuffle = false;

        // If level 0 then order is random
        if (this.state.level === '0'){
            shuffle = true;
        }

        this.api.getFilteredListFlashcard(
            {
                level: this.state.level,
                limit: this.state.limit,
                reverse: reverse,
                shuffle: shuffle

            },
            (data) => {
                if ( data.length > 0 ) {
                    this.setState({
                        flashcards: data,
                        flashcard: data[0],
                        loading: false
                    });
                } else {
                    this.setState({loading: false});
                    alert("Level " + this.state.level + " is empty");
                }
            },
            (err) => {
                alert(err)
            }
        );
    }

    okCallback = () => {
        let nextCard = () => {
            let flashcards = this.state.flashcards;
            let cur = this.state.current;
            if (flashcards !== null && cur < flashcards.length - 1) {
                this.setState({
                    current: cur + 1,
                    flashcard: flashcards[cur + 1]
                });
            } else {
                this.setState({
                    flashcard: null,
                    flashcards: null,
                    current:0
                });
            }
        }

        let flashcard = this.state.flashcard;
        const level = parseInt(flashcard.level);
        flashcard.level = Math.min(level + 1, 6)
        this.setState({loading: true});
        this.api.addFlashcard(
            flashcard,
            (data) => {
                nextCard();
                this.props.refreshStats();
                this.setState({loading: false});
            },
            (err) => {
                this.setState({loading: false});
                alert("Error during update object: " + err)
            }
        )
    }

    badCallback  = () => {
        let nextCard = () => {
            let cur = this.state.current;
            let flashcards = this.state.flashcards;
            if (flashcards !== null && cur < flashcards.length - 1) {
                this.setState({
                    current: cur + 1,
                    flashcard: flashcards[cur + 1]
                });
            } else {
                this.setState({
                    flashcard: null,
                    flashcards: null,
                    current:0
                });
            }
        }
        let flashcard = this.state.flashcard;
        flashcard.level = 1;
        this.setState({loading: true});
        this.api.addFlashcard(
            flashcard,
            (data) => {
                nextCard();
                this.props.refreshStats();
                this.setState({loading: false});
            },
            (err) => {
                this.setState({loading: false});
                alert("Error during update object: " + err)

            }
        )
    }

    handlePrev  = () => {
        if (this.state.flashcards !== null) {
            const newIndex = Math.max(0, this.state.current - 1);
            const flashcard = this.state.flashcards[newIndex]
            this.setState({ current: newIndex, flashcard: flashcard });
        }
    }

    handleNext  = () => {
        if (this.state.flashcards !== null) {
            const newIndex = Math.min(this.state.flashcards.length - 1,  this.state.current + 1);
            const flashcard = this.state.flashcards[newIndex]
            this.setState({ current: newIndex, flashcard: flashcard });
        }
    }

    render = () => {

        let tog = <Icon.Toggle2Off className='text-danger' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
        let display = "d-none";
        if (this.state.visible === true){
            tog = <Icon.Toggle2On className='text-success' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
            display = "d-block";
        }

        let flashcard = null;
        if (this.state.flashcard !== null){
            flashcard = <Flashcard
                key={this.state.flashcard.id}
                addOkPoints={this.props.addOkPoints}
                addBadPoints={this.props.addBadPoints}
                okCallback={this.okCallback}
                badCallback={this.badCallback}
                showPLword={this.props.fromPLtoEN}
                showENword={!this.props.fromPLtoEN}
                showButtons={true}
                flashcard={this.state.flashcard}
                api={this.props.api}
            />
        } else {
            flashcard =
                <div className='text-center text-secondary'>
                    <Icon.ExclamationTriangle size={50}/>
                    <h3 className=''>Nothing to show</h3>
                    <h6>Pull new package to continue</h6>
                </div>
        }

        let real_limit = 0
        if (this.state.flashcards !== null) {
            real_limit = Math.min(this.state.flashcards.length, this.state.limit);
        }

        return (
            <div className='bg-dark m-2 p-1 rounded-3'>

                <div className='row' onClick={(event) => {this.handleChangeVisible();}}>
                    <div className='col-9'>
                        <h4 className='text-secondary p-1 d-inline'>Lerning</h4>
                        <DotSpinner active={this.state.loading} />
                    </div>
                    <div className='col-3 text-end pe-5'>
                        {tog}
                    </div>
                </div>

                <div className={display}>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="input-group  input-group-sm mb-1">
                                <span className="input-group-text"><Icon.Box/></span>
                                <select className="form-select form-select-sm" value={this.state.level} onChange={this.handleChangeLevel}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text"><Icon.Download/></span>
                                <select className="form-select form-select-sm" value={this.state.limit} onChange={this.handleChangeLimit}>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className='btn btn-warning btn-sm rounded-3 w-100' onClick={() => {this.handleStart();}}>
                                <Icon.PlayFill size={25}/> Pull
                            </div>
                        </div>
                    </div>

                    <div className='row mt-2'>
                        <div className='col-2'></div>
                        <div className='col-8'>
                            <label className="form-label text-white">
                                Package progress ({this.state.current} / {real_limit})
                            </label>
                            <input
                                type="range"
                                className="form-range"
                                min="0"
                                max={real_limit}
                                value={this.state.current}
                                onChange={() => {}}
                            />
                        </div>
                    </div>

                    <div className='p-3 mx-auto '>
                        { flashcard }
                    </div>

                    <div className='row mt-2 mb-4'>
                        <div className='col-6'>
                            <div className='btn btn-sm btn-warning rounded-3 w-100' onClick={(event) => {this.handlePrev()}}>
                                <Icon.ArrowLeft size={25}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='btn btn-sm btn-warning rounded-3 w-100' onClick={(event) => {this.handleNext()}}>
                                <Icon.ArrowRight size={25}/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}



export class Box extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: null,
            visible: true,
        }
    }

    componentDidMount() {
        this.api = this.props.api;
        this.getStats();
    }

    getStats = () => {
        this.api.getStatisticsFlashcard(
            (data) => {
                this.setState({ stats: data });
            },
            (err) => {
                alert(err);
            }
        )
    }

    handleChangeVisible = () => {
        this.setState({visible: !this.state.visible});
    }

    render() {

        if (this.state.stats === null) {
            return <Spinner />
        }

        let tog = <Icon.Toggle2Off className='text-danger' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
        let display = "d-none";
        if (this.state.visible === true){
            tog = <Icon.Toggle2On className='text-success' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
            display = "d-block";
        }

        let statistics = [
            {
                value: this.state.stats[5],
                max: 350,
                prc: Math.min(Math.floor((this.state.stats[5] * 100) / 350 ), 100),
            },
            {
                value: this.state.stats[4],
                max: 250,
                prc: Math.min(Math.floor((this.state.stats[4] * 100) / 250 ), 100),
            },
            {
                value: this.state.stats[3],
                max: 175,
                prc: Math.min(Math.floor((this.state.stats[3] * 100) / 175 ), 100),
            },
            {
                value: this.state.stats[2],
                max: 125,
                prc: Math.min(Math.floor((this.state.stats[2] * 100) / 125 ), 100),
            },
            {
                value: this.state.stats[1],
                max: 100,
                prc: Math.min(Math.floor((this.state.stats[1] * 100) / 100 ), 100),
            },
        ]

        const progresses = statistics.map((item, index) => (
            <Progress key={5-index} value={item.value} level={5-index} max={item.max} prc={item.prc} />
        ));

        return (
        <div>
            <div className='bg-dark m-2 rounded-3'>

                <div className='row' onClick={(event) => {this.handleChangeVisible();}}>
                    <div className='col-9'>
                        <h4 className='text-secondary p-1'>Statistics</h4>
                    </div>
                    <div className='col-3 text-end pe-5'>
                        {tog}
                    </div>
                </div>

                <div className={display}>

                    <div className='row text-light mb-2'>
                        <div className='col-2 text-end'> <Icon.Archive/> </div>
                        <div className='col-6'>
                            <span className='badge bg-success w-100 text-dark'>{this.state.stats[6] } </span>
                        </div>
                    </div>

                    { progresses }

                    <div className='row text-light'>
                        <div className='col-2 text-end'> <Icon.QuestionDiamond/> </div>
                        <div className='col-6'>
                            <span className='badge bg-warning w-100 text-dark'>{ this.state.stats[0] } </span>
                        </div>
                    </div>
                    <br/>

                </div>
            </div>

            <Lerning
                api={this.props.api}
                addOkPoints={this.props.addOkPoints}
                addBadPoints={this.props.addBadPoints}
                refreshStats={this.getStats}
                fromPLtoEN={this.props.fromPLtoEN}
            />

        </div>
        )
    }

}
