import React from 'react';
import { Spinner } from "./utils.js"
import * as Icon from 'react-bootstrap-icons';


export class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            limit: this.props.filterParams.limit,
            pl: this.props.filterParams.pl,
            en: this.props.filterParams.en,
            level: this.props.filterParams.level,
        }
    }

    handleChangeVisible(event) {
        this.setState({visible: !this.state.visible});
    }

    handleFilter() {
        this.props.filterCallback(this.state);
    }

    handleChangeLimit = (event) => {
        this.setState({limit: event.target.value});
    }

    handleChangePL = (event) => {
        this.setState({pl: event.target.value});
    }

    handleChangeEN = (event) => {
        this.setState({en: event.target.value});
    }

    handleChangeLevel = (event) => {
        this.setState({level: event.target.value});
    }

    handleClear = (event) => {
        this.setState({
            pl: "",
            en: "",
            level: "",
        },
        () => {
            this.props.filterCallback(this.state)
        })
    }

    render() {

        let tog = <Icon.Toggle2Off className='text-danger' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
        let display = "d-none";
        if (this.state.visible === true){
            tog = <Icon.Toggle2On className='text-success' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
            display = "d-block";
        }

        return (

            <div className='bg-dark m-2 p-1 rounded-3'>

                <div className='row' onClick={(event) => {this.handleChangeVisible();}}>
                    <div className='col-9'>
                        <h4 className='text-secondary p-1'>Filter</h4>
                    </div>
                    <div className='col-3 text-end pe-5'>
                        {tog}
                    </div>
                </div>

                <div className={display}>

                    <div className='row'>
                        <div className='col'>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text">PL</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.pl}
                                    onChange={this.handleChangePL}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text">EN</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.en}
                                    onChange={this.handleChangeEN}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text"><Icon.Box/></span>
                                <select className="form-select" value={this.state.level} onChange={this.handleChangeLevel}>
                                    <option value=""> --- </option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text"><Icon.ArrowsExpand/></span>
                                <select className="form-select" value={this.state.limit} onChange={this.handleChangeLimit}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col text-start'>
                            <div
                                className='btn btn-warning btn-sm w-100 mt-1 rounded-3'
                                onClick={event => { this.handleClear(); }}
                            >
                                <Icon.X size={25} /> Clear
                            </div>
                        </div>
                        <div className='col text-end'>
                            <div
                                className='btn btn-success btn-sm w-100 mt-1 rounded-3'
                                onClick={event => { this.handleFilter(); }}
                            >
                                <Icon.Funnel size={25} /> Filter
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}


/* ------------------------------------------------------------------------------------------------*/
export class AddDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.flashcard !== null,
            msg: {
                text: "",
                type: "secondary"
            },
            flashcard: {
                id: (this.props.flashcard && this.props.flashcard.id) || "",
                pl: (this.props.flashcard && this.props.flashcard.pl) || "",
                en: (this.props.flashcard && this.props.flashcard.en) || "",
                pronunciation: (this.props.flashcard && this.props.flashcard.pronunciation) || "",
                level: (this.props.flashcard && this.props.flashcard.level) || 0
            }
        }
    }

    handleChangeVisible = (event) => {
        this.setState(
            {
                visible: !this.state.visible,
                msg: {text: "",  type: "secondary"},
            }
        );
    }

    handleChangePL = (event) => {
        this.setState(
            (prevState, props) => ({
                flashcard: {
                    ...prevState.flashcard,
                    pl: event.target.value
                },
            })
        );
    }

    handleChangeEN = (event) => {
        this.setState(
            (prevState, props) => ({
                flashcard: {
                    ...prevState.flashcard,
                    en: event.target.value
                },
            })
        );
    }

    handleChangePron = (event) => {
        this.setState(
            (prevState, props) => ({
                flashcard: {
                    ...prevState.flashcard,
                    pronunciation: event.target.value
                },
            })
        );
    }

    handleChangeLevel = (event) => {
        this.setState(
            (prevState, props) => ({
                flashcard: {
                    ...prevState.flashcard,
                    level: event.target.value
                },
            })
        );
    }

    handleClear = (event) => {
        this.setState({
            flashcard: {id: "", pl: "", en: "", pronunciation: "", level: 0},
            msg: {text: "",  type: "secondary"},
        })
    }

    handleAddFlashcard = (event) => {

        if (this.state.flashcard.pl === "" || this.state.flashcard.en === ""){
            this.setState({ msg: {text: "Fields PL and EN are required", type: "danger"} })
            return null;
        }

        this.setState({ msg: {text: "", type: ""} })
        this.props.api.addFlashcard(
            this.state.flashcard,
            (data) => {
                this.setState(
                    {
                        msg: {text: "Flashcard was added", type: "success"},
                        flashcard: data
                    },
                    () => {
                        this.props.reloadTableRowAfterUpdate(this.state.flashcard);
                    }
                );
            },
            (err) => {
                this.setState({ msg: {text: err, type: "danger"} })
            }
        );
    }

    handleTranslateToEN = (event) => {

        if (this.state.flashcard.pl === "") {
            this.setState({ msg: { text: "Fields PL it can't be empty", type: "danger" } })
            return null;
        }
        this.props.api.getTranslation(
            { "text_pl": this.state.flashcard.pl, "text_en": ""},
            (data) => {
                this.setState((prevState, props) => ({
                    flashcard: {
                        ...prevState.flashcard,
                        en: data.text_en
                    },
                }))
            },
            (err) => {
                this.setState({ msg: { text: err, type: "danger" } })
            }
        );
    }

    handleTranslateToPL = (event) => {
        if (this.state.flashcard.en === "") {
            this.setState({ msg: { text: "Fields EN it can't be empty", type: "danger" } })
            return null;
        }
        this.props.api.getTranslation(
            { "text_pl": "", "text_en": this.state.flashcard.en },
            (data) => {
                this.setState((prevState, props) => ({
                    flashcard: {
                        ...prevState.flashcard,
                        pl: data.text_pl
                    },
                }))
            },
            (err) => {
                this.setState({ msg: { text: err, type: "danger" } })
            }
        );
    }

    render () {

        let tog = <Icon.Toggle2Off className='text-danger' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
        let display = "d-none";
        if (this.state.visible === true){
            tog = <Icon.Toggle2On className='text-success' size={40} onClick={(event) => {this.handleChangeVisible();}}/>
            display = "d-block";
        }

        let message = null;
        if (this.state.msg.text !== "") {
            let cls = "p-2 m-1 rounded-3 bg-" + this.state.msg.type;
            message = (<h6 className={cls}>Message: {this.state.msg.text}</h6>)
        }

        return (
            <div className='bg-dark m-2 rounded-3'>

                <div className='row' onClick={(event) => {this.handleChangeVisible();}}>
                    <div className='col-9'>
                        <h4 className='text-secondary p-1'>Add new flashcard</h4>
                    </div>
                    <div className='col-3 text-end pe-5'>
                        {tog}
                    </div>
                </div>

                <div className={display}>

                    {message}

                    <div className='row mb-1'>
                        <div className='col'>
                            <div className="input-group input-group-sm px-1">
                                <span className="input-group-text">ID</span>
                                <input
                                    readOnly={true}
                                    type="text"
                                    className="form-control"
                                    name="id"
                                    value={this.state.flashcard.id}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>
                        <div className='col'>
                            <div className="input-group input-group-sm px-1">
                                <span className="input-group-text">PL</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="pl"
                                    value={this.state.flashcard.pl}
                                    onChange={this.handleChangePL}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>
                        <div className='col text-center'>
                            <div
                                className='btn btn-warning btn-sm w-50 mt-1 rounded-3'
                                onClick={event => { this.handleTranslateToEN(); }}
                            >
                                <Icon.ArrowDownShort size={15} />
                            </div>
                        </div>
                        <div className='col text-center'>
                            <div
                                className='btn btn-warning btn-sm w-50 mt-1 rounded-3'
                                onClick={event => { this.handleTranslateToPL(); }}
                            >
                                <Icon.ArrowUpShort size={15} />
                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>
                        <div className='col'>
                            <div className="input-group input-group-sm px-1">
                                <span className="input-group-text">EN</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="en"
                                    value={this.state.flashcard.en}
                                    onChange={this.handleChangeEN}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row mb-1'>
                        <div className='col'>
                            <div className="input-group input-group-sm px-1">
                                <span className="input-group-text"><Icon.Ear/></span>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="pronunciation"
                                    value={this.state.flashcard.pronunciation}
                                    onChange={this.handleChangePron}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='col'>
                            <div className="input-group input-group-sm px-1">
                                <span className="input-group-text"><Icon.Box/></span>
                                <select className="form-select" value={this.state.flashcard.level} onChange={this.handleChangeLevel}>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col text-start ms-1'>
                            <div
                                className='btn btn-warning btn-sm w-100 mt-1 rounded-3'
                                onClick={event => { this.handleClear(); }}
                            >
                                <Icon.X size={25} /> Clear
                            </div>
                        </div>
                        <div className='col text-end mb-1 me-1'>
                            <div
                                className='btn btn-success btn-sm w-100 mt-1 rounded-3'
                                onClick={event => { this.handleAddFlashcard(); }}
                            >
                                <Icon.CheckLg size={25} /> Save
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}


/* ------------------------------------------------------------------------------------------------*/
export class List extends React.Component {

  constructor(props) {
    super(props);
        this.state = {
            flashcards: null,
            flashcard: null,
            filterParams: {
                pl: "",
                en: "",
                level: "",
                limit: 5,
                shuffle: false,
                reverse: false
            }
        }
    }

    componentDidMount() {
        this.api = this.props.api;
        this.getFlashcards();
    }

    filterCallback = (filters) => {
        this.setState(
            { filterParams : filters },
            () => {
                this.getFlashcards()
            });
    }

    getFlashcards = () => {
        this.api.getFilteredListFlashcard(
            this.state.filterParams,
            (data) => {
                this.setState({
                    flashcards: data,
                });
            },
            (err) => {
                alert(err)
            }
        );
    }

    handleEditFlashcard = (event, id) => {
        let fc = null;
        for (let i = 0; i < this.state.flashcards.length; i++) {
            fc = this.state.flashcards[i]
            if (fc.id === id) {
                this.setState({flashcard: fc});
                window.scrollTo(0, 0);
                return null;
            }
        }
    }

    handleDeleteFlashcard = (event, id) => {
        this.api.deleteFlashcard(
            id,
            () => {
                this.getFlashcards()
            },
            (err) => {
                alert(err);
            }
        )
    }

    handlePronounciacionEN = (event, id) => {
        this.api.getPronounciacionEN(
            id,
            (data) => {
                let audio = new Audio(data.url);
                audio.play();
            },
            (err) => {
                alert(err);
            }
        )
    }

    reloadTableRowAfterUpdate = (updatedFlashcard) => {
        let flashcards = [...this.state.flashcards]
        for (let i = 0; i < flashcards.length; i++) {
            if (flashcards[i].id === updatedFlashcard.id){
                flashcards[i] = updatedFlashcard;
                break;
            }
        }
        this.setState({flashcards: flashcards});
    }

    render() {

        if (this.state.flashcards === null) {
            return <Spinner />
        }

        let rows = (
            <tr>
                <td colSpan={5} className="fs-6"> Table is empty - use filter to find words </td>
            </tr>
        )
        if (this.state.flashcards !== null) {
            rows = this.state.flashcards.map(
                (flashcard) => (
                    <tr key={flashcard.id}>
                        <td>
                            <div>PL: {flashcard.pl}</div>
                            <hr/>
                            <div>EN: {flashcard.en}</div>
                            <hr/>
                            <div>PR: {flashcard.pronunciation}</div>
                        </td>
                        <td className='text-center' > {flashcard.level} </td>
                        <td className='text-center position-relative'>
                            <div className='position-absolute top-50 start-50 translate-middle' style={{"maxWidth": "40px"}}>
                                <div
                                    className='btn btn-sm btn-primary me-1 mb-1 rounded-3'
                                    onClick={ event => this.handleEditFlashcard(event, flashcard.id) }
                                >
                                    <Icon.PencilSquare size={18}/>
                                </div>

                                <div
                                    className='btn btn-sm btn-warning me-1 mb-1 rounded-3'
                                    onClick={ event => this.handlePronounciacionEN(event, flashcard.id) }
                                >
                                    <Icon.Megaphone size={18}/>
                                </div>

                                <div
                                    className='btn btn-sm btn-danger rounded-3'
                                    onClick={ event => this.handleDeleteFlashcard(event, flashcard.id) }
                                >
                                    <Icon.Trash3 size={18}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            )
        }

        return (

            <div className="mt-2">

                <Filter
                    filterParams={this.state.filterParams}
                    filterCallback={this.filterCallback}
                />

                <AddDialog
                    api={this.props.api}
                    flashcard={this.state.flashcard}
                    reloadTableRowAfterUpdate={this.reloadTableRowAfterUpdate}
                    key={this.state.flashcard && this.state.flashcard.id}
                />

                <div className='m-2'>
                    <table className="table table-dark table-striped table-bordered rounded-3 align-middle">
                        <thead>
                            <tr className='text-center'>
                                <th>PL / EN / Pron.</th>
                                <th><Icon.Box/></th>
                                <th style={{"minWidth": "50px"}}><Icon.Gear/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
  }
