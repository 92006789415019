import React from 'react';
import { Flashcard } from './flashcard.js'
import { Spinner } from './utils.js'
import * as Icon from 'react-bootstrap-icons';


export class Random extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flashcard: null,
        }
    }

    componentDidMount() {
        this.api = this.props.api;
        this.getRandomFlashcard();
    }

    getRandomFlashcard = () => {
        this.api.getRandomFlashcard(
            (data) => {
                this.setState({
                    flashcard: data,
                });
            },
            (err) => {
                alert(err)
            }
        );
    }

    render() {

        if (this.state.flashcard === null) {
            return <Spinner />
        }

        let flashcard = null
        if (this.state.flashcard !== null) {
            flashcard = <Flashcard
                key={this.state.flashcard.id}
                addOkPoints={this.props.addOkPoints}
                addBadPoints={this.props.addBadPoints}
                okCallback={this.getRandomFlashcard}
                badCallback={this.getRandomFlashcard}
                showPLword={this.props.fromPLtoEN}
                showENword={!this.props.fromPLtoEN}
                showButtons={true}
                flashcard={this.state.flashcard}
                api={this.props.api}
            />
        }

        return (

            <div className='bg-dark m-2 p-2 rounded-3'>

                {flashcard}

                <div className="text-center m-3">
                    <button
                        className="btn btn-sm btn-warning w-100 rounded-3"
                        onClick={event => { this.getRandomFlashcard(); }}>
                        <Icon.ArrowClockwise size={25} /> Next
                    </button>
                </div>
            </div>
        );
    }
  }
