import jwt_decode from "jwt-decode";


export class Api {

    constructor(apiUrl, idToken, signInUrl) {
        this.apiUrl = apiUrl;
        this.idToken = idToken;

        // - endpoints:
        this.versionEP = "/version"
        this.filterListEP = "/api/flashcards"
        this.randomEP = "/api/flashcards/random"
        this.deleteEP = "/api/flashcards/<id>/"
        this.pronounciacionEnEP = "/api/flashcards/<id>/en-pronunciation"
        this.statisticsEP = "/api/level-statistics"
        this.translate = "/api/translate"
    }

    checkJWTTokenValid() {
        // Check expiration time for JWT
        // if token is no longer valid then reload
        // site

        const decoded = jwt_decode(this.idToken);
        const tstamp = decoded.exp;
        const tnow = Math.floor(Date.now() / 1000)
        // Session still valid
        if (tnow > tstamp) {
            window.location.reload(true);
        }
    }

    getVersion(successCallback, errorCallback) {
        this.checkJWTTokenValid();
        fetch(
            this.apiUrl + this.versionEP,
            {
                method: 'GET',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
            .then(response => response.json())
            .then(data => {
                successCallback(data);
            })
            .catch((error) => {
                errorCallback(error.toString());
            });
    }

    getRandomFlashcard(successCallback, errorCallback) {
        this.checkJWTTokenValid();
        fetch(
            this.apiUrl + this.randomEP,
            {
                method: 'GET',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then(response => response.json())
        .then(data => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error.toString());
        });
    }


    getFilteredListFlashcard(filter, successCallback, errorCallback) {
        this.checkJWTTokenValid();
        var url = new URL(this.apiUrl + this.filterListEP)
        let params = new URLSearchParams(filter)
        let keysForDel = [];

        // Remove empty values from filter
        params.forEach((value, key) => {
            if (value === "" || value === null) {
                keysForDel.push(key);
            }
        });
        keysForDel.forEach(key => {
            params.delete(key);
        });

        url.search = params.toString();
        fetch(
            url,
            {
                method: 'GET',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then(
            response => response.json()
            .then(data => {
                successCallback(data);
            })
        )
        .catch((error) => {
            errorCallback(error.toString());
        });
    }


    addFlashcard(flashcard, successCallback, errorCallback) {
        this.checkJWTTokenValid();
        var url = new URL(this.apiUrl + this.filterListEP)

        if (flashcard["id"] === undefined || flashcard["id"] === "") {
            flashcard["id"] = null;
        }

        fetch(
            url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(flashcard),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then(
            response => response.json()
            .then(data => {
                if (response.status === 200) {
                    successCallback(data);
                } else if ( data["message"] !== undefined ) {
                    errorCallback(data["message"]);
                } else {
                    errorCallback("Unknow error, return status " + response.status);
                }
            })
        )
        .catch((error) => {
            errorCallback(error.toString());
        });
    }


    deleteFlashcard(id, successCallback, errorCallback) {
        this.checkJWTTokenValid();
        var url = new URL(
            (this.apiUrl + this.deleteEP).replace("<id>", id)
        )

        fetch(
            url,
            {
                method: 'DELETE',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then((response) => {
            if (response.status === 204) {
                successCallback();
            } else {
                errorCallback("Unknow error, return status " + response.status);
            }
        })
        .catch((error) => {
            errorCallback(error.toString());
        });

    }


    getPronounciacionEN(id, successCallback, errorCallback) {
        this.checkJWTTokenValid();
        var url = new URL(
            (this.apiUrl + this.pronounciacionEnEP).replace("<id>", id)
        )

        fetch(
            url,
            {
                method: 'GET',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then(
            response => response.json()
            .then(data => {
                if (response.status === 200) {
                    successCallback(data);
                } else if ( data["message"] !== undefined ) {
                    errorCallback(data["message"]);
                } else {
                    errorCallback("Unknow error, return status " + response.status);
                }
            })
        )
        .catch((error) => {
            errorCallback(error.toString());
        });

    }


    getStatisticsFlashcard(successCallback, errorCallback) {
        this.checkJWTTokenValid();
        var url = new URL(this.apiUrl + this.statisticsEP)
        fetch(
            url,
            {
                method: 'GET',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then(
            response => response.json()
            .then(data => {
                successCallback(data);
            })
        )
        .catch((error) => {
            errorCallback(error.toString());
        });
    }


    getTranslation(data, successCallback, errorCallback) {
        this.checkJWTTokenValid();
        var url = new URL(this.apiUrl + this.translate)
        fetch(
            url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.idToken
                }
            }
        )
        .then(
            response => response.json()
                .then(data => {
                    if (response.status === 200) {
                        successCallback(data);
                    } else if (data["message"] !== undefined) {
                        errorCallback(data["message"]);
                    } else {
                        errorCallback("Unknow error, return status " + response.status);
                    }
                })
        )
        .catch((error) => {
            errorCallback(error.toString());
        });
    }

}
