import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app.js'
import { BrowserRouter } from "react-router-dom";

import 'bootstrap/scss/bootstrap.scss';
import './statics/styles/style.scss';


ReactDOM.createRoot(
    document.getElementById('root')
).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);
