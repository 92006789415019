import React from 'react';
import { Routes, Route, NavLink } from "react-router-dom";
import { Box } from "./box.js"
import { List } from "./list.js"
import { Random } from "./random.js"
import { AuthRequire, SignInCallback, SignOutCallback } from "./auth.js"
import { Api } from "./api.js"
import * as Icon from 'react-bootstrap-icons';


export class App extends React.Component {

    constructor(props) {
        super(props);

        // Take env from system variable REACT_APP_FLASHCARDS_ENV
        this.f_version = "0.4.0"
        this.b_version = "?.?.?"
        this.env = process.env.REACT_APP_FLASHCARDS_ENV;
        this.apiUrl = process.env.REACT_APP_FLASHCARDS_API_URL;

        this.cognito_client_id = process.env.REACT_APP_FLASHCARDS_COGNITO_CLIENT_ID;
        this.signInCallback = process.env.REACT_APP_FLASHCARDS_SIGNIN_CALLBACK;
        this.signOutCallback = process.env.REACT_APP_FLASHCARDS_SIGNOUT_CALLBACK;
        this.signInUrl = "https://flashcards.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=" + this.cognito_client_id + "&response_type=token&scope=email+openid+phone&redirect_uri=" + this.signInCallback;
        this.signOutUrl = "https://flashcards.auth.eu-central-1.amazoncognito.com/logout?client_id=" + this.cognito_client_id + "&logout_uri=" + this.signOutCallback;

        console.log("Front version : " + this.f_version)
        console.log("SignInUrl : " + this.signInUrl)
        console.log("signOutUrl : " + this.signOutUrl)

        this.state = {
            apiUrl: this.apiUrl,
            auth: {
                idToken: null,
                accessToken: null,
                expiresIn: null
            },
            points: {
                ok: 1,
                bad: 2,
            },
            fromPLtoEN: true,
            env: this.env,
            f_version: this.f_version,
            b_version: this.b_version
        };
        this.api = null
    }

    addOkPoints = () => {
        let ok = this.state.points.ok + 1;
        let bad = this.state.points.bad;
        this.setState({ points: {ok: ok, bad: bad} })
    }

    addBadPoints = () => {
        let ok = this.state.points.ok ;
        let bad = this.state.points.bad + 1;
        this.setState({ points: {ok: ok, bad: bad} })
    }

    handleChangePLtoEN(event) {
        this.setState({fromPLtoEN: !this.state.fromPLtoEN});
    }


    resetPoints = () => {
        this.setState({ points: {ok: 0, bad: 0} })
    }

    setAuth = (idToken, accessToken, expiresIn) => {
        this.setState(prevState => {
            let auth = Object.assign({}, prevState.auth);
            auth.idToken = idToken;
            auth.accessToken = accessToken;
            auth.expiresIn = expiresIn;
            this.api = new Api(this.state.apiUrl, idToken);
            this.api.getVersion(
                (data) => { this.setState({ b_version: data.VERSION }) },
                (err) => { console.log(err); }
            );
            return { auth };
        });
        this.resetPoints();
    }

    render() {

        let tog = <Icon.Toggle2Off className='mx-2 pb-1 text-danger' size={35} onClick={(event) => {this.handleChangePLtoEN() }}/>
        if (this.state.fromPLtoEN === false) {
            tog = <Icon.Toggle2On className='mx-2 pb-1 text-success' size={35} onClick={(event) => {this.handleChangePLtoEN() }}/>
        }

      return (
        <div className="App bg-secondary">

            <header>
                <div className="px-3 py-2 bg-dark text-white border-bottom border-2">
                    <div className="container">
                        <div className="d-flex flex-wrap">
                            <h6 className='col-12 text-secondary text-center'> 3CP FlashCards </h6>
                            <ul className="nav col-12 justify-content-center text-center text-small">
                                <li className='text-center w-25'>
                                    <NavLink to="/" className={ ({isActive}) => (isActive ? "border-bottom border-3 border-warning nav-link text-warning" : 'nav-link text-white')}>
                                        <Icon.Inboxes size={30}/><br/> Box
                                    </NavLink>
                                </li>
                                <li className='text-center w-25'>
                                    <NavLink to="/list" className={ ({isActive}) => (isActive ? "border-bottom border-3 border-warning nav-link text-warning" : 'nav-link text-white')}>
                                        <Icon.CardList size={30}/><br/> List
                                    </NavLink>
                                </li>
                                <li className='text-center w-25'>
                                    <NavLink to="/random" className={ ({isActive}) => (isActive ? "border-bottom border-3 border-warning nav-link text-warning" : 'nav-link text-white')}>
                                        <Icon.Dice5 size={30}/><br/> Random
                                    </NavLink>
                                </li>
                                <li className='text-center w-25'>
                                    <a href={this.signOutUrl} className="nav-link text-white">
                                        <Icon.Power size={30} /><br/> Signout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <main className="bg-dark bg-opacity-75">
                <div className='container bg-secondary'>

                    <div className='row'>

                        <div className='col'>
                            <div className='text-start'>
                                <div className='badge bg-dark mt-1 d-inline'>
                                    PL {tog} EN
                                </div>
                            </div>
                        </div>

                        <div className='col'>
                            <div className='text-end'>
                                <div className='badge bg-dark mt-1 d-inline'>
                                    <span className="me-1">Points : </span>
                                    <span className="badge bg-success me-1 px-3"> {this.state.points.ok} </span>
                                    <span className="badge bg-danger m-1 px-3"> {this.state.points.bad} </span>
                                    <Icon.XSquare
                                        className='text-warning m-1'
                                        size={15}
                                        onClick={ event => { this.resetPoints(); } }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Routes>
                            <Route
                                path="/"
                                element={
                                    <AuthRequire auth={this.state.auth} signInUrl={this.signInUrl} >
                                        <Box
                                            api={this.api}
                                            addOkPoints={this.addOkPoints}
                                            addBadPoints={this.addBadPoints}
                                            fromPLtoEN={this.state.fromPLtoEN}
                                        />
                                    </AuthRequire>
                                }
                            />
                            <Route
                                path="list"
                                element={
                                    <AuthRequire auth={this.state.auth} signInUrl={this.signInUrl} >
                                        <List api={this.api} />
                                    </AuthRequire>
                                }
                            />
                            <Route
                                path="random"
                                element={
                                    <AuthRequire auth={this.state.auth} signInUrl={this.signInUrl} >
                                        <Random
                                            addOkPoints={this.addOkPoints}
                                            addBadPoints={this.addBadPoints}
                                            api={this.api}
                                            fromPLtoEN={this.state.fromPLtoEN}
                                        />
                                    </AuthRequire>
                                }
                            />
                            <Route
                                path="signin_callback"
                                element={
                                    <SignInCallback auth={this.state.auth} setAuth={this.setAuth} />} />
                            <Route
                                path="signout_callback"
                                element={
                                    <SignOutCallback signInUrl={this.signInUrl} auth={this.state.auth} setAuth={this.setAuth} />
                                }
                            />
                    </Routes>

                    <div className='text-center text-white bg-dark m-2 rounded-3' style={{"fontSize": "11px"}}>
                        Env: { this.state.env } |
                        Front: { this.state.f_version } |
                        Api: { this.state.b_version }
                    </div>

                </div>
            </main>

        </div>
      );
    }
  }
