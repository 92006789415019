import React from 'react';
import { Navigate } from 'react-router-dom';


export class AuthRequire extends React.Component {

    render() {
        if (this.props.auth.idToken !== null) {
            return <>{this.props.children}</>
        } else {
            window.location.replace(this.props.signInUrl);
        }
    }
}


export class SignInCallback extends React.Component {

    componentDidMount() {
        const hash = window.location.hash.substr(1);
        const urlParams = new URLSearchParams(hash);
        const idToken = urlParams.get('id_token');
        const accessToken = urlParams.get('access_token');
        const expiresIn= urlParams.get('expires_in');
        this.props.setAuth(idToken, accessToken, expiresIn);
    }

    render() {
        return <Navigate to="/"></Navigate>
    }
}


export class SignOutCallback extends React.Component{

    componentDidMount() {
        this.props.setAuth(null, null, null);
    }

    render() {
        return <Navigate to="/"></Navigate>
    }

}

